import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import Layout from '../components/global/layout'
import Masthead from '../components/layout/masthead'
import {SliceZones} from "../components/slices/slice-zones";

const Careers = ({ data }) => {
  if (!data) 
    return null

  const document = data.prismicCareers.data;

  return (
    <Layout pageTitle={document.title.text}
            metaTitle={document.meta_title.text}
            metaDescription={document.meta_description.text || document.summary.text}
            shareImage={document.share_image.url}>
      <Masthead subtitle={document.subtitle.text}
                title={document.title.text}
                summary={document.summary.text}
                image={document.image ? document.image.url : null} />
      <SliceZones slices={document.body} />
    </Layout>
  )
}

export const query = graphql`
  query CareersQuery {
    prismicCareers {
      _previewable
      id
      lang
      type
      url
      data {
        subtitle {
          text
        }
        title {
          text
        }
        summary {
          text
        }
        meta_title {
          text
        }
        image {
          alt
          url
        }
        meta_description {
          text
        }
        share_image {
          url
        }
        body {
          ... on PrismicCareersDataBodyContentWithMedia {
            id
            primary {
              contentmedia_subtitle {
                text
              }
              contentmedia_title {
                text
              }
              contentmedia_content {
                text
              }
              contentmedia_image {
                url
              }
              contentmedia_button_text {
                text
              }
              contentmedia_button_link {
                url
              }
              contentmedia_button2_text {
                text
              }
              contentmedia_button2_link {
                url
              }
              contentmedia_image_alignment
            }
            slice_type
          }
          ... on PrismicCareersDataBodyCallToAction {
            id
            primary {
              cta_button_link {
                url
              }
              cta_button_text
              cta_description {
                text
              }
              cta_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyRichTextContent {
            id
            primary {
              richtextcontent_content {
                raw
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyTestimonial {
            id
            primary {
              testimonial_quote {
                text
              }
              testimonial_link_text {
                text
              }
              testimonial_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyWhyWorkWithUs {
            id
            primary {
              whyworkwithus_point1 {
                text
              }
              whyworkwithus_point2 {
                text
              }
              whyworkwithus_point3 {
                text
              }
              whyworkwithus_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyUseCases {
            id
            items {
              usecases_usecase {
                text
              }
            }
            primary {
              usecases_link_text {
                text
              }
              usecases_title {
                text
              }
              usescases_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyTestimonialCarousel {
            id
            items {
              testimonialcarousel_author {
                text
              }
              testimonialcarousel_authorjobtitle {
                text
              }
              testimonialcarousel_image {
                url
              }
              testimonialcarousel_quote {
                text
              }
              testimonialcarousel_title {
                text
              }
            }
            primary {
              testimonialcarousel_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyHowItWorksCarousel {
            id
            primary {
              howitworkscarousel_title {
                text
              }
            }
            items {
              howitworkscarousel_title {
                text
              }
              howitworkscarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyFeaturedProjects {
            id
            primary {
              featuredprojects_startprojectlink {
                url
              }
              featuredprojects_title {
                text
              }
            }
            items {
              featuredprojects_document {
                document {
                  ... on PrismicProject {
                    id
                    data {
                      image {
                        url
                      }
                      summary {
                        text
                      }
                      title {
                        text
                      }
                    }
                    tags
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyFeaturedJobs {
            id
            primary {
              featuredjobs_title {
                text
              }
            }
            items {
              featuredjobs_document {
                document {
                  ... on PrismicJobDescription {
                    id
                    url
                    data {
                      summary {
                        text
                      }
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyServicesCarousel {
            id
            items {
              servicescarousel_image {
                url
              }
              servicescarousel_circleimage {
                url
              }
              servicescarousel_fullscreenimage {
                url
              }
              servicescarousel_link {
                url
              }
              servicescarousel_navigationtitle {
                text
              }
              servicescarousel_summary {
                text
              }
              servicescarousel_title {
                text
              }
            }
            primary {
              servicescarousel_subtitle {
                text
              }
              servicescarousel_title {
                text
              }
              servicescarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyFeaturedBlogPosts {
            id
            items {
              featuredblogposts_document {
                document {
                  ... on PrismicInsightArticle {
                    id
                    data {
                      listing_image {
                        url
                      }
                      title {
                        text
                      }
                      summary {
                        text
                      }
                    }
                    url
                    tags
                  }
                }
                type
              }
            }
            primary {
              featuredblogposts_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicCareersDataBodyListing {
            id
            primary {
              listing_title {
                text
              }
              listing_buttonlink {
                url
              }
              listing_buttontext {
                text
              }
              listing_button2link {
                url
              }
              listing_button2text {
                text
              }
            }
            items {
              image {
                url
              }
              listing_title {
                text
              }
            }
            slice_type
          }
        }
      }
    }
  }
`

export default withPrismicPreview(Careers, repositoryConfigs)